<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div id="app_version">Ver {{app_version}}</div>
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">

          <h2 class="brand-text text-primary ml-1" v-if="domain == 'www.cloudfit.pro' || domain == 'x.cloudmedia.hk:9090' || domain == '192.168.1.253:9190'">
            CloudBeauty Pro
          </h2>
          </b-link>

        <b-card-title class="mb-1">
          Welcome to CloudBeauty Pro! 👋 
        </b-card-title>
        <b-card-text class="mb-2">
          Please sign-in to your account and start the adventure
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >

            <!-- email -->
            <b-form-group
              label-for="username"
              label="Username"
            >
              <validation-provider
                #default="{ errors }"
                name="Username"
                rules="required"
              >
                <b-form-input
                  id="username"
                  v-model="username"
                  name="login-username"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Username"
                  autofocus
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Password</label>
                <!--<b-link :to="{name:'auth-forgot-password-v1'}">
                  <small>Forgot Password?</small>
                </b-link>-->
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Password"
                    autocomplete="off"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
              @click="login"
            >
              Sign in
            </b-button>
          </b-form>
        </validation-observer>
        <!--
        <b-card-text class="text-center mt-2">
          <span>New on our platform? </span>
          <b-link :to="{name:'auth-register-v1'}">
            <span>Create an account</span>
          </b-link>
        </b-card-text>

        <div class="divider my-2">
          <div class="divider-text">
            or
          </div>
        </div>

        <div class="auth-footer-btn d-flex justify-content-center">
          <b-button
            href="javascript:void(0)"
            variant="facebook"
          >
            <feather-icon icon="FacebookIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="twitter"
          >
            <feather-icon icon="TwitterIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="google"
          >
            <feather-icon icon="MailIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="github"
          >
            <feather-icon icon="GithubIcon" />
          </b-button>
        </div>
        -->
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      app_version : '',
      username: '',
      client:'',
      password: '',
      status: '',
      domain : 'www.cloudfit.pro',
      // validation rules
      required,
      email,
    }
  },
  created () {
    this.test2()
    localStorage.setItem('reader_action', 'staff_check_in')
    this.app_version = process.env.VUE_APP_VERSION
    console.log(process.env.VUE_APP_VERSION)
    console.log(this.app_version)
    const host = window.location.host
    console.log(host)
    this.domain = host
    this.client = host.split(".")

  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    login() {
      this.$http.defaults.headers.Version = this.app_version
      this.$http.post(process.env.VUE_APP_API_BASE+'/auth/', {
        username: this.username,
        password: this.password,
        version: this.app_version
      })
        .then(res => {
          if (res.data.result === true) {
            const userData = res.data
            this.$store.commit('center/UPDATE_CENTER_ID', userData.default_center_id)
            this.$store.commit('center/UPDATE_CENTER_NAME', userData.default_center_name)
            this.$http.defaults.headers.Token = res.data.token
            this.$i18n.locale = userData.language
            this.$toast({
              component: ToastificationContent,
              position: 'top-center',
              props: {
                title: `Welcome ${userData.fullName}`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
              },
            })

            localStorage.setItem('userData', JSON.stringify(userData))
            this.$ability.update(userData.ability)
            localStorage.setItem('reader_action', 'normal')
            this.$router.replace(userData.landing_url)
              .then(() => {

              })
            console.log(userData.ability)
            //     localStorage.setItem('userData', JSON.stringify(userData))
          //    this.$ability.update(userData.ability)
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-center',
              props: {
                title: 'Error',
                icon: 'XIcon',
                variant: 'danger',
                text: res.data.message,
              },
            })
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
#app_version {
  position:fixed;
  bottom:10px;
  right:10px;
  text-weight:bold;
}
</style>
